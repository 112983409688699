<template>
  <v-row class="mx-0">
    <v-col md="12" class="py-0">
      <v-btn-toggle color="cyan" group class="mb-2">
        <v-btn
          color="cyan"
          class="custom-bold-button"
          v-on:click="openDialog('goods', 'Product')"
          value="goods"
        >
          Product
        </v-btn>
        <v-btn
          color="cyan"
          class="custom-bold-button mx-2"
          v-on:click="openDialog('service', 'Service')"
          value="service"
        >
          Service
        </v-btn>
      </v-btn-toggle>
      <table class="line-item-multiple-table" width="100%">
        <thead>
          <tr>
            <th class="text-left" width="55%">
              &nbsp;&nbsp;&nbsp;Product/Service
            </th>
            <th class="text-left" width="15%">&nbsp;&nbsp;&nbsp;Rate</th>
            <th class="text-left" width="15%">&nbsp;&nbsp;&nbsp;Quantity</th>
            <th class="text-right pr-8" width="15%">&nbsp;&nbsp;&nbsp;Total</th>
          </tr>
        </thead>
        <tbody>
          <template v-if="liSelectedLineItem.length">
            <tr
              v-for="(row, index) in liSelectedLineItem"
              :key="index"
              class="alternate-listing-row line-item-listing-row"
              :class="{ 'custom-border-top': index > 0 }"
            >
              <td valign="top">
                <div class="d-flex flex-column">
                  <div>
                    <v-text-field
                      dense
                      filled
                      placeholder="Product/Service"
                      solo
                      v-model="row.product"
                      flat
                      color="cyan"
                      class="mb-2"
                    >
                      <template v-slot:append>
                        <v-chip
                          small
                          label
                          class="ml-4 custom-status font-weight-600 custom-grey-border text-uppercase"
                          text-color="white"
                          :color="
                            row.product_type == 'service'
                              ? 'orange darken-1 white--text'
                              : 'cyan darken-1 white--text'
                          "
                        >
                          {{
                            row.product_type == "service"
                              ? "Service"
                              : "Product"
                          }}
                        </v-chip>
                      </template>
                    </v-text-field>
                  </div>
                  <div>
                    <v-textarea
                      auto-grow
                      dense
                      filled
                      v-model="row.description"
                      color="cyan"
                      placeholder="Description"
                      solo
                      flat
                      row-height="10"
                      class="mb-2"
                    />
                  </div>
                </div>
              </td>
              <td valign="top">
                <v-text-field
                  dense
                  filled
                  placeholder="Rate"
                  solo
                  flat
                  v-on:change="updateRowTotal(index)"
                  v-on:keypress="limitDecimal($event, row.rate)"
                  v-model="row.rate"
                  type="number"
                  color="cyan"
                  class="mb-2"
                />
              </td>
              <td valign="top">
                <v-text-field
                  dense
                  filled
                  placeholder="Quantity"
                  solo
                  flat
                  v-on:change="updateRowTotal(index)"
                  v-on:keypress="limitDecimal($event, row.quantity)"
                  v-model="row.quantity"
                  type="number"
                  color="cyan"
                  class="mb-2"
                />
              </td>
              <td valign="top">
                <div class="d-flex flex-column">
                  <div>
                    <v-text-field
                      dense
                      filled
                      placeholder="Total"
                      solo
                      flat
                      reverse
                      v-model="row.total"
                      type="number"
                      readonly
                      color="cyan"
                      class="mb-2"
                    />
                  </div>
                  <div class="text-right">
                    <v-btn
                      icon
                      v-on:click="deleteLineItem(row.uuid)"
                      color="red lighten-1"
                    >
                      <v-icon>mdi-delete</v-icon>
                    </v-btn>
                  </div>
                </div>
              </td>
            </tr>
          </template>
          <tr v-else>
            <td colspan="4">
              <p
                class="m-0 row-not-found text-center font-weight-500 font-size-16"
              >
                <img
                  :src="$assetURL('media/error/empty.png')"
                  class="row-not-found-image"
                />
                Uhh... There are no product/service at the moment.
              </p>
            </td>
          </tr>
        </tbody>
      </table>
      <div class="my-4">
        <table width="100%">
          <tr>
            <td></td>
            <td style="width: 200px" class="text-right">
              <label>Sub Total</label>
            </td>
            <td style="width: 200px"></td>
            <td
              align="right"
              class="pr-5"
              style="min-width: 100px; max-width: 200px; width: 150px"
            >
              <label>{{ formatMoney(liCalculation.subtotal) }}</label>
            </td>
          </tr>
          <tr>
            <td></td>
            <td class="text-right"><label>Discount</label></td>
            <td>
              <div class="d-flex w-100">
                <div style="min-width: 150px">
                  <v-text-field
                    placeholder="Discount"
                    dense
                    filled
                    solo
                    color="cyan"
                    flat
                    type="number"
                    v-model="liCalculation.discount_value"
                    v-on:change="
                      updateLineItemState(
                        'dbCalculation.discount_value',
                        $event
                      )
                    "
                    v-on:keypress="
                      limitDecimal($event, liCalculation.discount_value)
                    "
                  />
                </div>
                <div style="width: 80px">
                  <v-select
                    :items="liDiscountTypeList"
                    v-model="liCalculation.discount_type"
                    dense
                    filled
                    flat
                    hide-details
                    solo
                    item-text="type"
                    item-value="value"
                    color="cyan"
                    item-color="cyan"
                    v-on:change="
                      updateLineItemState('dbCalculation.discount_type', $event)
                    "
                    class="discount-append-outer"
                  />
                </div>
              </div>
            </td>
            <td align="right" class="pr-5">
              <label>{{ formatMoney(liCalculation.discount_amount) }}</label>
            </td>
          </tr>
          <tr>
            <td></td>
            <td class="text-right">
              <label>Tax [GST {{ liCalculation.tax_value }}%]</label>
            </td>
            <td>
              <v-checkbox
                v-on:change="
                  updateLineItemState('dbCalculation.tax_active', $event)
                "
                v-model="liCalculation.tax_active"
                hide-details
                :true-value="1"
                :false-value="0"
                color="cyan"
                class="m-0 p-0"
              />
            </td>
            <td class="pr-5" align="right">
              <label>{{ formatMoney(liCalculation.tax_amount) }}</label>
            </td>
          </tr>
          <tr>
            <td></td>
            <td class="text-right"><label>Adjustment</label></td>
            <td>
              <v-text-field
                style="width: 250px"
                placeholder="Adjustment"
                dense
                filled
                solo
                color="cyan"
                flat
                type="number"
                v-model="liCalculation.adjustment"
                v-on:change="
                  updateLineItemState('dbCalculation.adjustment', $event)
                "
                v-on:keypress="limitDecimal($event, liCalculation.adjustment)"
              />
            </td>
            <td class="pr-5" align="right">
              <label>{{ formatMoney(liCalculation.adjustment) }}</label>
            </td>
          </tr>
          <tr>
            <td></td>
            <td class="text-right"><label>Grand Total</label></td>
            <td></td>
            <td class="pr-5" align="right">
              <label>{{ formatMoney(liCalculation.total) }}</label>
            </td>
          </tr>
        </table>
      </div>
      <SelectLineItemDialog
        :dialog="productDialog"
        :type="productType"
        :title="productString"
        :key="`job-line-item-dialog-${uniqueId}`"
        v-on:close="productDialog = false"
      />
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters } from "vuex";
import ManageLineItem from "@/core/plugins/line.item.plugin";
import {
  PERFORM_CALCULATION,
  DELETE_LINE_ITEM,
  UPDATE_LINE_ITEM_STATE,
} from "@/core/services/store/line.item.module";
import SelectLineItemDialog from "@/view/components/SelectLineItemDialog.vue";
import AppConfiguration from "@/core/config/app.config.js";
import CommonFunctionMixin from "@/core/lib/common/common.functions.mixin";
import { head, toNumber } from "lodash";

export default {
  name: "job-ctx-line-item",
  mixins: [CommonFunctionMixin],
  data() {
    return {
      uniqueId: Number(new Date()),
      productType: "goods",
      productString: "goods",
      productDialog: false,
      itemInstance: null,
    };
  },
  methods: {
    getAppConfig() {
      const { entity } = AppConfiguration.get();
      const currentEntity = head(entity);
      this.updateLineItemState(
        "dbCalculation.tax_value",
        currentEntity?.gstvalue ?? 8
      );
      this.updateLineItemState(
        "dbCalculation.tax_active",
        currentEntity?.gst ?? 0
      );
    },
    updateRowTotal(index) {
      const { rate, quantity } = this.liSelectedLineItem[index];
      this.liSelectedLineItem[index].total =
        parseFloat(rate) * parseInt(quantity);
      this.performCalculation();
    },
    deleteLineItem(uuid) {
      this.$store.dispatch(DELETE_LINE_ITEM, uuid);
    },
    performCalculation() {
      clearTimeout(this.timeOut);
      this.timeOut = setTimeout(() => {
        this.$store.dispatch(PERFORM_CALCULATION);
      }, 200);
    },
    updateLineItemState(key, value) {
      this.$store.commit(UPDATE_LINE_ITEM_STATE, {
        key,
        value: toNumber(value),
      });
      this.performCalculation();
    },
    openDialog(type, string) {
      this.productType = type;
      this.productString = string;
      this.uniqueId = Number(new Date());
      this.$nextTick(() => {
        this.productDialog = true;
      });
    },
  },
  mounted() {
    this.itemInstance = new ManageLineItem();
    this.getAppConfig();
  },
  components: {
    SelectLineItemDialog,
  },
  computed: {
    ...mapGetters([
      "liCalculation",
      "liDiscountTypeList",
      "liSelectedLineItem",
    ]),
  },
};
</script>
