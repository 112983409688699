<template>
  <v-row class="mx-0">
    <v-col :md="vShowCalendar ? 4 : 12" class="py-0">
      <v-row>
        <v-col :md="vShowCalendar ? 12 : 8">
          <v-row class="custom-grey-border">
            <v-col
              md="12"
              class="light-gray-background custom-border-bottom d-flex justify-space-between"
            >
              <div
                style="font-size: 19px"
                class="text-uppercase font-weight-700 color-custom-blue"
              >
                Schedule
              </div>
              <div>
                <v-btn
                  class="mx-2 custom-bold-button white--text"
                  color="cyan"
                  right
                  small
                  :disabled="jobLoading"
                  depressed
                  v-on:click="updateCalendar()"
                >
                  <v-icon small class="mr-2">mdi-calendar-blank</v-icon>
                  <span v-if="vShowCalendar">Hide Calendar</span>
                  <span v-else>Show Calendar</span>
                </v-btn>
              </div>
            </v-col>
            <v-col :md="vShowCalendar ? 12 : 4">
              <label class="ml-2" for="visit-one-off-start-date"
                >Start Date</label
              >
              <DatePicker
                :disabled="jobLoading"
                key="visit-one-off-start-date"
                id="visit-one-off-start-date"
                placeholder="Start Date"
                :value="oneOffSchedule.start_date"
                v-on:change="
                  updateScheduleState('dbOneOffSchedule.start_date', $event);
                  updateScheduleState('dbOneOffSchedule.end_date', $event);
                "
              />
            </v-col>
            <v-col :md="vShowCalendar ? 12 : 4">
              <label class="ml-2" for="visit-one-off-start-time"
                >Start Time</label
              >
              <TimePicker
                :disabled="jobLoading"
                key="visit-one-off-start-time"
                id="visit-one-off-start-time"
                placeholder="Start Time"
                :value="oneOffSchedule.start_time"
                v-on:change="
                  updateScheduleState('dbOneOffSchedule.start_time', $event)
                "
                type="start-time"
              />
            </v-col>
            <v-col :md="vShowCalendar ? 12 : 4">
              <label class="ml-2" for="visit-one-off-end-time">End Time</label>
              <TimePicker
                :disabled="jobLoading"
                key="visit-one-off-end-time"
                id="visit-one-off-end-time"
                placeholder="End Time"
                :min-time="oneOffSchedule.start_time"
                :value="oneOffSchedule.end_time"
                v-on:change="
                  updateScheduleState('dbOneOffSchedule.end_time', $event)
                "
                type="end-time"
              />
            </v-col>
            <v-col md="12">
              <p
                class="m-0 p-4 font-size-16 font-weight-500 light-gray-background custom-grey-border"
              >
                This job will start on
                {{ formatDate(oneOffSchedule.start_date) }}
                {{ oneOffSchedule.start_time }} and finish on
                {{ formatDate(oneOffSchedule.end_date) }}
                {{ oneOffSchedule.end_time }}
              </p>
            </v-col>
          </v-row>
        </v-col>
        <v-col :md="vShowCalendar ? 12 : 4">
          <ScheduleTeam :key="`job-one-off-schedule-team-${scheduleKey}`" />
        </v-col>
      </v-row>
    </v-col>
    <v-col v-if="vShowCalendar" md="8">
      <Calendar
        :key="`job-one-off-calendar-${scheduleKey}`"
        :show-calendar="vShowCalendar"
      ></Calendar>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters } from "vuex";
import ScheduleTeam from "@/view/components/ScheduleTeam.vue";
import DatePicker from "@/view/components/QDatePicker.vue";
import TimePicker from "@/view/components/TimePicker.vue";
import Calendar from "@/view/pages/partials/Calendar.vue";
import {
  UPDATE_DEFAULT_DATE,
  UPDATE_CALENDAR_ACTION,
  UPDATE_SCHEDULE_STATE,
  RESET_ENGINEER,
} from "@/core/services/store/job.module";
import moment from "moment-timezone";

moment.tz.setDefault(process.env.VUE_APP_TIMEZONE);

export default {
  name: "job-ctx-one-off-schedule",
  data() {
    return {
      todayDate: moment(new Date()).format("YYYY-MM-DD"),
      scheduleKey: Number(new Date()),
    };
  },
  methods: {
    formatDate(date) {
      if (moment(date).isValid()) {
        return moment(date).format("DD MMMM YYYY");
      }
    },
    updateScheduleState(key, value) {
      this.$store.commit(UPDATE_SCHEDULE_STATE, { key, value });
      this.$store.commit(RESET_ENGINEER);
      this.updateDefaultDate();
    },
    updateDefaultDate() {
      this.$nextTick(() => {
        this.$store.dispatch(UPDATE_DEFAULT_DATE, "one-off");
      });
    },
    updateCalendar() {
      this.$store.commit(UPDATE_CALENDAR_ACTION);
    },
  },
  mounted() {
    this.updateDefaultDate();
  },
  components: {
    ScheduleTeam,
    Calendar,
    TimePicker,
    DatePicker,
  },
  computed: {
    ...mapGetters(["vShowCalendar", "oneOffSchedule", "jobLoading"]),
  },
};
</script>
