<template>
  <Dialog :common-dialog="dialog" :dialog-width="dialogWidth">
    <template v-slot:title>
      Visit Schedule ({{ scheduleOutput.length }}) {{ getDuration() }}
    </template>
    <template v-slot:body>
      <v-container>
        <table width="100%">
          <tr
            v-for="(row, index) in scheduleOutput"
            :key="index"
            :class="{ 'visit-row-hover': row.isEdited }"
          >
            <td
              class="font-size-14 font-weight-500"
              align="center"
              valign="middle"
            >
              {{ index + 1 }}.
            </td>
            <td>
              <DatePicker
                v-model="row.startDate"
                key="visit-show-schedule-start-date"
                id="visit-show-schedule-start-date"
                :min-date="todayDate"
                placeholder="Start Date"
                v-on:change="
                  updateDay($event, index);
                  row.isEdited = 1;
                "
              />
            </td>
            <td>
              <v-text-field
                readonly
                solo
                flat
                dense
                :disabled="!row.status"
                filled
                hide-details
                :value="row.daysOfWeek"
              ></v-text-field>
            </td>
            <td>
              <TimePicker
                :disabled="!row.status"
                v-model="row.startTime"
                key="visit-show-schedule-start-time"
                id="visit-show-schedule-start-time"
                v-on:change="row.isEdited = 1"
                placeholder="Start Time"
              />
            </td>
            <td>
              <TimePicker
                :disabled="!row.status"
                v-model="row.endTime"
                key="visit-show-schedule-end-time"
                id="visit-show-schedule-end-time"
                v-on:change="row.isEdited = 1"
                placeholder="End Time"
              />
            </td>
            <td align="center" valign="middle">
              <v-switch
                class="m-0 p-0"
                color="cyan"
                dense
                hide-details
                :false-value="0"
                :true-value="1"
                v-on:change="row.isEdited = 1"
                v-model="row.status"
                inset
              ></v-switch>
            </td>
          </tr>
        </table>
      </v-container>
    </template>
    <template v-slot:action>
      <v-btn
        class="mx-2 custom-grey-border custom-bold-button white--text"
        color="cyan"
        v-on:click="validateSchedule()"
      >
        Save
      </v-btn>
      <v-btn
        class="mx-2 custom-grey-border custom-bold-button"
        v-on:click="$emit('close', true)"
      >
        Close
      </v-btn>
    </template>
  </Dialog>
</template>

<script>
import { mapGetters } from "vuex";
import { toSafeInteger } from "lodash";
import DatePicker from "@/view/components/QDatePicker.vue";
import TimePicker from "@/view/components/TimePicker.vue";
import Dialog from "@/view/pages/partials/Dialog.vue";
import { SET_RECURRING_DATE } from "@/core/services/store/job.module";
import moment from "moment-timezone";

moment.tz.setDefault(process.env.VUE_APP_TIMEZONE);

export default {
  name: "job-ctx-show-schedule",
  data() {
    return {
      todayDate: moment(new Date()).format("YYYY-MM-DD"),
    };
  },
  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    updateDay(date, index) {
      this.scheduleOutput[index].daysOfWeek = moment(date).format("dddd");
    },
    getDuration() {
      //
    },
    validateSchedule() {
      this.$store.commit(SET_RECURRING_DATE, this.scheduleOutput);
      this.$emit("next", true);
    },
  },
  components: {
    Dialog,
    DatePicker,
    TimePicker,
  },
  computed: {
    ...mapGetters(["scheduleOutput"]),
    dialogWidth() {
      return toSafeInteger((document.body.clientWidth / 100) * 70);
    },
  },
};
</script>
