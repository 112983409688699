<template>
  <Dialog :common-dialog="dialog" :dialog-width="dialogWidth">
    <template v-slot:title>
      Select {{ title }}s
      <v-icon class="mx-2 color-custom-blue" small>mdi-record</v-icon> Line Item
    </template>
    <template v-slot:body>
      <v-row>
        <v-col v-if="liCategories.length" md="12" class="alphabets-filter">
          <div class="alphabets-scrollable">
            <v-btn
              :disabled="liLoading"
              depressed
              v-on:click="getLineItem(null, 'category')"
              :class="{
                'cyan--text': null == category,
                'cyan white--text': null != category,
              }"
              class="custom-bold-button"
              small
              >All</v-btn
            >
            <v-btn
              :disabled="liLoading"
              depressed
              v-for="(row, index) in liCategories"
              v-on:click="getLineItem(row, 'category')"
              :class="{
                'cyan--text': row == category,
                'cyan white--text': row != category,
              }"
              class="custom-bold-button"
              small
              :key="index"
              >{{ row }}</v-btn
            >
          </div>
        </v-col>
        <v-col v-if="liAlphabets.length" md="12" class="alphabets-filter">
          <div class="alphabets-scrollable">
            <v-btn
              :disabled="liLoading"
              depressed
              v-on:click="getLineItem(null, 'alphabet')"
              :class="{
                'cyan--text': null == alphabet,
                'cyan white--text': null != alphabet,
              }"
              class="custom-bold-button"
              small
              >All</v-btn
            >
            <v-btn
              :disabled="liLoading"
              depressed
              v-for="(row, index) in liAlphabets"
              v-on:click="getLineItem(row, 'alphabet')"
              :class="{
                'cyan--text': row == alphabet,
                'cyan white--text': row != alphabet,
              }"
              class="custom-bold-button"
              small
              :key="index"
              >{{ row }}</v-btn
            >
          </div>
        </v-col>
        <v-col md="12">
          <v-layout>
            <v-flex md6 class="font-weight-700 font-size-17 color-custom-blue">
              <v-btn
                small
                depressed
                :disabled="liLoading"
                :class="{
                  'green white--text': showSelected,
                  'white green--text': !showSelected,
                }"
                class="font-size-17 font-weight-700"
                v-on:click="showSelected = !showSelected"
                >{{ selected.length }}</v-btn
              >
              out of
              <v-btn
                small
                depressed
                :disabled="liLoading"
                class="font-size-17 font-weight-700"
                color="white cyan--text"
                v-on:click="showSelected = false"
                >{{ liLineItem.length }}</v-btn
              >
              {{ title }} Selected
            </v-flex>
            <v-flex md6>
              <v-text-field
                v-model="search"
                placeholder="Start typing then press enter to search..."
                dense
                filled
                solo
                flat
                :disabled="liLoading"
                color="cyan"
                append-icon="mdi-magnify"
                clearable
                v-on:click:clear="
                  search = null;
                  getLineItem(search, 'search');
                "
                v-on:keyup.enter="getLineItem(search, 'search')"
                v-on:click:append="getLineItem(search, 'search')"
              ></v-text-field>
            </v-flex>
          </v-layout>
        </v-col>
        <v-col md="12">
          <table class="line-item-multiple-table" width="100%">
            <thead>
              <tr>
                <th class="text-left">
                  <v-checkbox
                    v-model="checkAll"
                    v-on:change="updateCheckAll($event)"
                    hide-details
                    color="cyan"
                    class="m-0 p-0"
                    :disabled="liLoading || !liLineItem.length"
                  />
                </th>
                <th class="text-left" width="55%">
                  &nbsp;&nbsp;&nbsp;{{ title }}
                </th>
                <th class="text-left" width="15%">&nbsp;&nbsp;&nbsp;Rate</th>
                <th class="text-left" width="15%">
                  &nbsp;&nbsp;&nbsp;Quantity
                </th>
                <th class="text-left" width="15%">&nbsp;&nbsp;&nbsp;Total</th>
              </tr>
            </thead>
            <tbody>
              <template v-for="(row, index) in liLineItem">
                <tr
                  :key="`alternate-listing-row-${index}`"
                  class="alternate-listing-row line-item-listing-row"
                  :class="{ 'custom-border-top': index > 0 }"
                  v-if="checkSelected(row)"
                >
                  <td valign="top" class="text-left">
                    <v-checkbox
                      v-on:change="updateSelected(row, $event)"
                      v-model="row.status"
                      hide-details
                      color="cyan"
                      class="m-0 p-0"
                    />
                  </td>
                  <td valign="top">
                    <div class="d-flex flex-column">
                      <div>
                        <v-text-field
                          dense
                          filled
                          v-model="row.product"
                          :placeholder="title"
                          solo
                          flat
                          color="cyan"
                          class="mb-2"
                        />
                      </div>
                      <div>
                        <v-textarea
                          auto-grow
                          dense
                          filled
                          color="cyan"
                          v-model="row.description"
                          placeholder="Description"
                          solo
                          flat
                          row-height="10"
                          class="mb-2"
                        />
                      </div>
                    </div>
                  </td>
                  <td valign="top">
                    <v-text-field
                      dense
                      filled
                      v-model="row.rate"
                      placeholder="Rate"
                      v-on:change="updateRowTotal(index)"
                      v-on:keypress="limitDecimal($event, row.rate)"
                      solo
                      flat
                      type="number"
                      color="cyan"
                      class="mb-2"
                    />
                  </td>
                  <td valign="top">
                    <v-text-field
                      dense
                      filled
                      v-model="row.quantity"
                      placeholder="Quantity"
                      v-on:change="updateRowTotal(index)"
                      v-on:keypress="limitDecimal($event, row.quantity)"
                      solo
                      flat
                      type="number"
                      color="cyan"
                      class="mb-2"
                    />
                  </td>
                  <td valign="top">
                    <v-text-field
                      dense
                      filled
                      v-model="row.total"
                      placeholder="Total"
                      solo
                      flat
                      type="number"
                      readonly
                      color="cyan"
                      class="mb-2"
                    />
                  </td>
                </tr>
              </template>
            </tbody>
          </table>
        </v-col>
      </v-row>
    </template>
    <template v-slot:action>
      <v-btn
        :disabled="liLoading"
        class="custom-grey-border custom-bold-button"
        v-on:click="$emit('close', true)"
        >Cancel</v-btn
      >
      <v-btn
        :disabled="liLoading"
        color="cyan white--text"
        class="custom-bold-button"
        v-on:click="saveLineItem()"
        >Save</v-btn
      >
    </template>
  </Dialog>
</template>

<script>
import { mapGetters } from "vuex";
import Dialog from "@/view/pages/partials/Dialog.vue";
import {
  SET_SELECTED_LINE_ITEM,
  GET_LINE_ITEM,
  CLEAR_DIALOG_LINE_ITEM,
} from "@/core/services/store/line.item.module";
import { toSafeInteger, map, cloneDeep } from "lodash";
import { ErrorEventBus, InitializeError } from "@/core/lib/message.lib";

export default {
  name: "job-ctx-select-line-item",
  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: "",
    },
    title: {
      type: String,
      default: "",
    },
  },
  watch: {
    dialog(param) {
      this.manageDialog(param);
    },
  },
  data() {
    return {
      checkAll: false,
      search: null,
      alphabet: null,
      category: null,
      selected: [],
      showSelected: false,
    };
  },
  methods: {
    updateRowTotal(index) {
      const { rate, quantity } = this.liLineItem[index];
      this.liLineItem[index].total = parseFloat(rate) * parseInt(quantity);
    },
    saveLineItem() {
      if (!this.selected.length) {
        ErrorEventBus.$emit(
          "update:error",
          InitializeError("Please select atleast one line item.")
        );
        return false;
      }
      this.$store.dispatch(SET_SELECTED_LINE_ITEM, this.selected);
      this.$nextTick(() => {
        this.$emit("close", true);
      });
    },
    manageDialog(param) {
      if (param) {
        this.findLineItem();
      } else {
        this.$store.commit(CLEAR_DIALOG_LINE_ITEM);
      }
    },
    getLineItem(value, key) {
      this[key] = value;
      this.checkAll = false;
      this.$nextTick(() => {
        this.findLineItem();
      });
    },
    updateSelected(row, status) {
      const preSelected = map(cloneDeep(this.selected), "product_id");
      if (status) {
        if (!preSelected.includes(row.product_id)) {
          this.selected.push(row);
        }
      } else {
        this.selected = this.selected.filter(
          (rw) => rw.product_id != row.product_id
        );
      }
      this.validateCheckAll();
    },
    updateAllSelected() {
      const preSelected = map(cloneDeep(this.selected), "product_id");
      for (let i = 0; i < this.liLineItem.length; i++) {
        if (this.liLineItem[i].status) {
          if (!preSelected.includes(this.liLineItem[i].product_id)) {
            this.selected.push(this.liLineItem[i]);
          }
        } else {
          this.selected = this.selected.filter(
            (rw) => rw.product_id != this.liLineItem[i].product_id
          );
        }
      }
      this.validateCheckAll();
    },
    validateCheckAll() {
      const rowChecked = [
        ...new Set(map(cloneDeep(this.liLineItem), "status")),
      ];
      if (rowChecked.includes(false)) {
        this.checkAll = false;
      } else {
        this.checkAll = true;
      }
    },
    checkSelected(row) {
      if (this.showSelected) {
        return row.status;
      }
      return true;
    },
    initPreSelected() {
      let selected = map(this.selected, "product_id");
      for (let i = 0; i < this.liLineItem.length; i++) {
        if (selected.includes(this.liLineItem[i].product_id)) {
          this.liLineItem[i].status = true;
        }
      }
      this.validateCheckAll();
    },
    updateCheckAll(status) {
      for (let i = 0; i < this.liLineItem.length; i++) {
        this.liLineItem[i].status = status;
      }
      this.$nextTick(() => {
        this.updateAllSelected(status);
      });
    },
    findLineItem() {
      this.$store
        .dispatch(GET_LINE_ITEM, {
          type: this.type,
          search: this.search,
          alphabet: this.alphabet,
          category: this.category,
        })
        .then(() => {
          this.initPreSelected();
        });
    },
  },
  mounted() {
    console.log("mounted");
  },
  components: {
    Dialog,
  },
  computed: {
    ...mapGetters(["liLoading", "liAlphabets", "liLineItem", "liCategories"]),
    dialogWidth() {
      return toSafeInteger((document.body.clientWidth / 100) * 70);
    },
  },
};
</script>
